import axios from "../core/axios";
import { paramsParser } from "../core/paramsParser";
import { assertSuccess } from "../core/assert";
import { Headers } from "./generic";
import type {
  FindReactionParams,
  GetReactionSummaryBatchParams,
  ReactableResources,
  ReactionEntity,
  ReactionSummaryEntity,
  ReactionSummaryExpandableResources,
  ReactionType,
} from "../types/reaction";
import type { CollectionResult, MessageResult, Result } from "../types/result";

export class Reaction {
  static async getReactionSummary(
    entityType: ReactableResources,
    entityId: string,
    includes: ReactionSummaryExpandableResources[] = [],
  ) {
    const resp = await axios<Result<ReactionSummaryEntity>>(
      `/reaction/summary/${entityType}/${entityId}${paramsParser({
        includes,
      })}`,
    );

    return assertSuccess(resp.data).data;
  }

  static async findReactions(
    entityType: ReactableResources,
    params: FindReactionParams = {},
  ) {
    if (!params?.entityIds?.length)
      return {
        data: [],
        meta: { total: 0 },
      };

    const resp = await axios<CollectionResult<ReactionEntity>>(
      `/reaction/${entityType}${paramsParser(params)}`,
    );

    const data = assertSuccess(resp.data);

    return {
      data: data.data,
      meta: data.meta,
    };
  }

  static async getReactionSummaryBatch(
    entityType: ReactableResources,
    params: GetReactionSummaryBatchParams = {},
  ) {
    if (!params?.entityIds?.length)
      return {
        data: [],
        meta: { total: 0 },
      };

    const resp = await axios<CollectionResult<ReactionSummaryEntity>>(
      `/reaction/summary/${entityType}${paramsParser(params)}`,
    );

    const data = assertSuccess(resp.data);

    return {
      data: data.data,
      meta: data.meta,
    };
  }

  static async createReaction(
    entityType: ReactableResources,
    entityId: string,
    reactionType: ReactionType,
    auth: string,
  ) {
    const resp = await axios<Result<ReactionEntity>>(
      `/reaction/${entityType}/${entityId}`,
      {
        method: "POST",
        headers: Headers.Bearer(auth),
        data: { reactionType },
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async deleteReaction(
    entityType: ReactableResources,
    entityId: string,
    auth: string,
  ) {
    const resp = await axios<MessageResult<"Reaction was removed">>(
      `/reaction/${entityType}/${entityId}`,
      {
        method: "DELETE",
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).result === "ok";
  }
}
